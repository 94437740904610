import React from "react";
import { Box, Heading, Text, UnorderedList, ListItem } from "@chakra-ui/react";

export const DrinksSecurityComponent: React.FC<{ props: any }> = ({
  props,
}) => {
  return (
    <Box maxW="6xl" mx="auto" p="6" data-aos="fade-up" data-aos-duration="3000">
      <Heading
        as="h1"
        fontSize="2xl"
        fontWeight="bold"
        mb="4"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        {props.dataSecurity.title}
      </Heading>
      <Text fontSize="sm" mb="2">
        <strong>Last updated:</strong> {props.dataSecurity.lastUpdated}
      </Text>
      {props.dataSecurity.content.map((section: any, index: any) => (
        <Box key={index} mt={index !== 0 ? "6" : undefined}>
          <Heading
            as="h2"
            fontSize="xl"
            fontWeight="semibold"
            mb="2"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {section.title}
          </Heading>
          <Text data-aos="fade-up" data-aos-duration="1000">
            {section.description}
          </Text>
          {section.points && (
            <UnorderedList mt="2" pl="5">
              {section.points.map((point: any, index: any) => (
                <ListItem
                  key={index}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  {point}
                </ListItem>
              ))}
            </UnorderedList>
          )}
          {section.additionalInfo && (
            <Text mt="2">{section.additionalInfo}</Text>
          )}
        </Box>
      ))}
      {props.stepOne && (
        <Box mt="6">
          <Heading
            as="h1"
            fontSize="2xl"
            fontWeight="bold"
            mb="4"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {props.stepOne.title}
          </Heading>
          {props.stepOne.content.map((section: any, index: any) => (
            <Box key={index} mt={index !== 0 ? "6" : undefined}>
              <Heading
                as="h2"
                fontSize="xl"
                fontWeight="semibold"
                mb="2"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {section.title}
              </Heading>

              {section.points && (
                <UnorderedList mt="2" pl="5">
                  {section.points.map((point: any, index: any) => (
                    <ListItem
                      key={index}
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      {point}
                    </ListItem>
                  ))}
                </UnorderedList>
              )}
            </Box>
          ))}
        </Box>
      )}
      {props.stepTwo && (
        <Box mt="6">
          <Heading
            as="h1"
            fontSize="2xl"
            fontWeight="bold"
            mb="4"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {props.stepTwo.title}
          </Heading>
          {props.stepTwo.content.map((section: any, index: any) => (
            <Box key={index} mt={index !== 0 ? "6" : undefined}>
              <Heading
                as="h2"
                fontSize="xl"
                fontWeight="semibold"
                mb="2"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {section.title}
              </Heading>

              {section.points && (
                <UnorderedList mt="2" pl="5">
                  {section.points.map((point: any, index: any) => (
                    <ListItem
                      key={index}
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      {point}
                    </ListItem>
                  ))}
                </UnorderedList>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

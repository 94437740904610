import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AssetsService } from "../../service/assets-service";
import { NotFoundComponent } from "../not-fount/NotFoundComponent";
import { DrinksSecurityComponent } from "./projects/DrinksSecurityComponent";
import { ApplicationPolicyEnum } from "../../util/enums/application-policy-enum";

export const DataSecurityInformationComponent = () => {
  const { appCode } = useParams();

  const [dataInformation, setDataInformation] = useState<any>();

  useEffect(() => {
    setDataInformation(AssetsService.getDateSecurityApplication(appCode ?? ""));
  }, [appCode]);

  let content;
  switch (appCode) {
    case ApplicationPolicyEnum.BeveragesCluster:
      content = <DrinksSecurityComponent props={dataInformation} />;
      break;
    case ApplicationPolicyEnum.Logimanta:
      break;
    default:
      content = <NotFoundComponent code={appCode} />;
      break;
  }

  return (
    <div>
      {!dataInformation ? <NotFoundComponent code={appCode} /> : content}
    </div>
  );
};
